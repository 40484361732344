<template>
  <div>
    <div style="padding: 5em 50px 20px">
      <h3 class="uk-text-background">対象者選択</h3>
      <hr />
    </div>
    <div
      v-show="
        !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
      "
    >
      <q-toggle
        v-if="all_check_flg == true"
        v-model="all_check_flg"
        @click="all_checked"
        label="全選択"
      />
      <q-toggle
        v-else
        v-model="all_check_flg"
        label="全選択解除"
        @click="all_checked"
      />
      <div class="q-pa-md">
        <q-table
          class="my-sticky-header-table"
          flat
          bordered
          :rows="employee_list"
          :columns="columns"
          row-key="name"
          :pagination="initialPagination"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="emp_code">{{ props.row.emp_code }}</q-td>
              <q-td key="name">{{ props.row.name }}</q-td>
              <q-td key="furigana">{{ props.row.furigana }}</q-td>
              <q-td key="workplace_name">{{ props.row.workplace_name }}</q-td>
              <q-td key="target">
                <input
                  type="checkbox"
                  name=""
                  v-model="targets[props.row.emp_code]"
                  @change="fn_calc_price"
                />
              </q-td>
              <q-td key="answers">
                <q-radio
                  v-model="answers[props.row.emp_code]"
                  val="False"
                  label="Web回答"
                />
                <q-radio
                  v-model="answers[props.row.emp_code]"
                  val="True"
                  label="アンケート用紙"
                />
              </q-td>
              <q-td key="presentation">
                <q-radio
                  v-model="presentation[props.row.emp_code]"
                  val="False"
                  label="Web"
                />
                <q-radio
                  v-model="presentation[props.row.emp_code]"
                  val="True"
                  label="紙"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <!-- <div style="overflow-y: auto; max-height: 80vh">
        <table>
          <tr>
            <th rowspan="2">社員番号</th>
            <th rowspan="2">氏名</th>
            <th rowspan="2">フリガナ</th>
            <th rowspan="2">所属部署</th>
            <th rowspan="2" @click="all_checked">対象者選択</th>
            <th colspan="2">回答方法</th>
            <th colspan="2">結果提示方法</th>
          </tr>
          <tr>
            <th>Web</th>
            <th>アンケート用紙</th>
            <th>Web</th>
            <th>紙</th>
          </tr>
          <tbody class="fixed03">
            <tr v-for="(d, index) in employee_list" :key="d">
              <td>{{ d.emp_code }}</td>
              <td>{{ d.name }}</td>
              <td>{{ d.furigana }}</td>
              <td>{{ d.workplace_name }}</td>
              <td></td>
              <td>
                <input
                  type="radio"
                  value="0"
                  :disabled="!targets[index]"
                  v-model="answers[index]"
                  @change="fn_calc_price"
                />
              </td>
              <td>
                <input
                  type="radio"
                  value="1"
                  :disabled="!targets[index]"
                  v-model="answers[index]"
                  @change="fn_calc_price"
                />
              </td>
              <td>
                <input
                  type="radio"
                  value="0"
                  :disabled="!targets[index]"
                  v-model="presentation[index]"
                  @change="fn_calc_price"
                />
              </td>
              <td>
                <input
                  type="radio"
                  value="1"
                  :disabled="!targets[index]"
                  v-model="presentation[index]"
                  @change="fn_calc_price"
                />
              </td>
            </tr>
          </tbody>
        </table> 
      </div> -->

      <div class="uk-container uk-flex uk-flex-center uk-margin-bottom">
        <button
          type="button"
          name="button"
          @click="send_data"
          class="uk-button uk-button-primary uk-width-1-3"
        >
          確定
        </button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      columns: [
        {
          name: "emp_code",
          required: true,
          label: "社員番号",
          align: "left",
          field: (employee_list) => employee_list.emp_code,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "name",
          required: true,
          label: "氏名",
          align: "left",
          field: (employee_list) => employee_list.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "furigana",
          required: true,
          label: "フリガナ",
          align: "left",
          field: (employee_list) => employee_list.furigana,
          format: (val) => `${val}`,
        },
        {
          name: "workplace_name",
          required: true,
          label: "所属部署",
          align: "left",
          field: (employee_list) => employee_list.workplace_name,
          format: (val) => `${val}`,
          sortable: true,
        },
        { name: "target", label: "対象者選択" },
        { name: "answers", label: "回答方法" },
        {
          name: "presentation",
          label: "結果提示方法",
        },
      ],

      sc_id: 0,
      employee_list: "",
      targets: {},
      do_something: true,
      analysis_group_code: null,
      answers: {},
      presentation: {},
      targets_ans_paper_count: null,
      targets_ans_web_count: null,
      targets_presentation_paper_count: null,
      targets_presentation_web_count: null,
      count: 0,
      price: 0,
      all_check_flg: true,
      all_targets: 0,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 0,
        rowsPerPage: 50,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック対象者選択";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.sc_id = this.$store.state.sc_id;
    this.$store.commit("resetImplementationID", {});
    if (this.$store.state.login_status_c) {
      if (this.$store.state.group_analysis == 1) {
        this.get_target_busyo_employee();
      } else {
        this.get_info_employee();
      }
      this.fn_calc_price();
      if (this.$store.state.group_analysis == 1) {
        this.analysis_group_code = this.$store.state.analysis_group_code;
      }
    }
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.push("/client-login");
      }
    },
    fn_calc_price() {
      this.count = 0;
      this.targets_ans_web_count = 0;
      this.targets_ans_paper_count = 0;
      this.targets_presentation_web_count = 0;
      this.targets_presentation_paper_count = 0;
      for (let i = 0; i < this.targets.length; i++) {
        if (this.targets[i] == true) {
          if (this.answers[i] == 0) {
            this.targets_ans_web_count += 1;
          } else if (this.answers[i] == 1) {
            this.targets_ans_paper_count += 1;
          }
          if (this.presentation[i] == 0) {
            this.targets_presentation_web_count += 1;
          } else if (this.presentation[i] == 1) {
            this.targets_presentation_paper_count += 1;
          }
          this.count += 1;
        }
      }
      this.price =
        550 *
        (this.targets_ans_paper_count + this.targets_presentation_paper_count);
      this.price +=
        250 *
        (this.targets_ans_web_count + this.targets_presentation_web_count);
      return this.price;
    },
    get_info_employee() {
      this.$axios
        .post("/client/get_info_employee", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          mode: 2,
        })
        .then(
          function (response) {
            if (response.data.flg) {
              this.employee_list = response.data.result;
              for (let i = 0; i < this.employee_list.length; i++) {
                this.targets[this.employee_list[i]["emp_code"]] = true;
                this.answers[this.employee_list[i]["emp_code"]] = "False";
                this.presentation[this.employee_list[i]["emp_code"]] = "False";
              }
            } else {
              if (response.data.status == 4) {
                console.log("データ未登録");
              }
            }
            this.initialPagination = {
              sortBy: "desc",
              descending: false,
              page: 0,
              rowsPerPage: 50,
              // rowsNumber: xx if getting data from a server
            };
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    get_target_busyo_employee() {
      this.$axios
        .post("/client/get_target_busyo_employee", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          sc_id: this.sc_id,
          mode: 2,
        })
        .then(
          function (response) {
            console.log(response);
            if (response.data.flg) {
              this.employee_list = response.data.result;
              for (let i = 0; i < this.employee_list.length; i++) {
                this.targets.push(false);
                this.answers.push(0);
                this.presentation.push(0);
              }
            } else {
              if (response.data.status == 4) {
                console.log("データ未登録");
              }
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    send_data() {
      if (!this.do_something) return;
      if (!window.confirm("依頼を確定しますか？")) return;
      this.do_something = false;
      if (this.targets.length == 0) {
        alert("ストレスチェックの対象者を選択してください");
        this.do_something = true;
      } else {
        this.$axios
          .post("/client/finalize_request", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            sc_id: this.sc_id,
            answers: this.answers,
            presentations: this.presentation,
            targets: this.targets,
          })
          .then(
            function (response) {
              console.log(response);
              if (response.data.flg) {
                alert("依頼を確定しました");
                this.$store.state.grouping = false;
                this.$store.commit("reset_analysis_group_code", {});
                this.do_something = true;
                this.$router.push("/client-menu");
              } else {
                if (response.data.status == 3) {
                  console.log(
                    "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
                  );
                  this.$router.push("/error");
                }
                this.do_something = true;
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              window.alert("エラーが発生しました");
              console.log(error);
              this.do_something = true;
            }.bind(this)
          );
      }
    },
    all_checked() {
      for (let i in this.targets) {
        this.targets[i] = this.all_check_flg;
      }
      this.fn_calc_price();
    },
  },
};
</script>
<style lang="sass">
.my-sticky-header-table
  /* height or max-height is important */
  height: 80%

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #00b4ff
    text-align: center

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>

<!-- <style scoped>
table {
  margin-left: 0;
  width: 100%;
  max-height: 100%;
}

tr td {
  text-align: center;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.fixed01 {
  position: sticky;
  top: 0px;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}

.fixed02 {
  position: sticky;
  top: 15px;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: 1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.fixed03 {
  top: 15px;
  color: #000000;
  background: #ffffff;
}
</style> -->
